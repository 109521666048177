html {
  scroll-behavior: smooth;
}

.icon-bg-secondary {
  background-color: $secondary;
}

.shadow-secondary {
  box-shadow: 0 .5rem 1.125rem -.5rem rgba(31, 39, 73, .9) !important;
}

.splash-section {
  video {
    box-shadow: -1px -1px 19px 1px rgba(0, 0, 0, .75);
  }
}

[data-bs-theme="light"] {
  .splash-section {
    .bg-gradient-translucent {
      background: linear-gradient(180deg, rgba(255, 255, 255, .7) 0%, rgba(255, 255, 255, .5) 50%, rgba(255, 255, 255, 1) 100%) !important;
    }

    p {
      color: $secondary;
    }
  }
}

[data-bs-theme="dark"] {
  .splash-section {
    .bg-gradient-translucent {
      background: linear-gradient(180deg, rgba(11, 15, 25, .7) 0%, rgba(11, 15, 25, 1) 100%) !important;
    }

    h2 {
      color: #fff;
    }
  }
}

.page-loading {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9999;
  width: 100%;
  height: 100%;
  visibility: hidden;
  background-color: #fff;
  opacity: 0;
  transition: all .4s .2s ease-in-out;
}

[data-bs-theme="dark"] .page-loading {
  background-color: #0b0f19;
}

.page-loading.active {
  visibility: visible;
  opacity: 1;
}

.page-loading-inner {
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  text-align: center;
  opacity: 0;
  transition: opacity .2s ease-in-out;
  transform: translateY(-50%);
}

.page-loading.active > .page-loading-inner {
  opacity: 1;
}

.page-loading-inner > span {
  display: block;
  font-size: 1rem;
  font-weight: 400;
  color: #9397ad;
}

[data-bs-theme="dark"] .page-loading-inner > span {
  color: #fff;
  opacity: .6;
}

.page-spinner {
  display: inline-block;
  width: 2.75rem;
  height: 2.75rem;
  margin-bottom: .75rem;
  vertical-align: text-bottom;
  border: .15em solid #b4b7c9;
  border-right-color: transparent;
  border-radius: 50%;
  animation: spinner .75s linear infinite;
}

[data-bs-theme="dark"] .page-spinner {
  border-color: rgba(255, 255, 255, .4);
  border-right-color: transparent;
}

@keyframes spinner {
  100% {
    transform: rotate(360deg);
  }
}
